<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :form="queryForm">
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderNo" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="下单人手机号">
              <a-input v-model="queryParam.mobile" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="入库状态">
              <a-select  placeholder="请选择" v-model="queryParam.loadStatus" default-value="">
<!--              <a-select  placeholder="请选择"  @change="changeLoadStatus" v-decorator="['queryParam.loadStatus']">-->
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="2">已入库</a-select-option>
                <a-select-option value="1">未入库</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="企业客户">
              <!--              <a-input v-model="queryParam.paymentCustomerCode" style="width: 100%" placeholder="客户识别码" />-->
              <a-select v-model="queryParam.paymentCustomerCode" placeholder="请选择" >
                <a-select-option v-for="item in qyCustomers" :key="item.code">{{ item.nickname }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="下单时间从：">
              <a-date-picker v-model="queryParam.orderDateStart" style="width: 100%" placeholder="请输入日期" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="到" class="dislodge-script">
              <a-date-picker v-model="queryParam.orderDateEnd" style="width: 100%" placeholder="请输入日期" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="仓库">
              <a-select
                v-model="thisWarehouse"
                placeholder="请选择仓库"
                @change="handleChange3"
              >
                <a-select-option v-for="(item, index) in warehouseList" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 4) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="
                  () => {
                    queryParam = {}
                    this.thisWarehouse = undefined
                    this.$route.params.loadStatus=null//工作台传入的参数清空
                  }
                "
                >重置</a-button
              >
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="inWarehouse()" v-action:but_less_load_dispatch>批量入库</a-button>&nbsp;&nbsp;
      <a-button type="primary" @click="exportOrders()" v-action:but_less_load_updatewarehouse>导出</a-button>&nbsp;&nbsp;
      <a-button type="primary" @click="importOrders()" v-action:but_less_load_updatewarehouse>导入</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content' , y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="edit(record)" v-action:but_less_load_orderinfo>详情 &ensp;|&ensp;</a>
           <a @click="inWarehouseSingle(record.id)" v-action:but_less_load_dispatch>入库</a>
          <!--如果需要权限 v-action:roleBD -->
<!--          <a @click="inWarehouse(record)" v-action:but_less_load_inwarehouse>入库</a>-->
        </template>
      </span>
    </s-table>




    <a-modal
      key="importTransport"
      title="导入运单"
      :width="400"
      :visible="visible_import"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="handleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{height:'auto'}"
    >
      <div>
        <a-form  :form="form">
          <a-form-item
            label="选择"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-upload
              name="file"
              :max-count="1"
              class="avatar-uploader"
              :show-upload-list="false"
              @change="uploadChange"
              :headers="headers"
              action="/service_provider/order/importInStorage"
            >
              <a-button id="showfilename">
                上传文件
              </a-button>
            </a-upload>

          </a-form-item>

          <div id="errormes" style="overflow-y: auto;height: 100px">

          </div>

        </a-form>
      </div>
    </a-modal>

  </a-card>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { STable } from '@/components'
import {
  findListDriver,
  orderFindPage,
  findListWarehouse,
  updateOrderWarehouse,
  loadDispacth,
  unLoadDispacth,
  updateInWarehouseStatus, findListCustomer,
} from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          width: '220px',
          key: 'transactionNo',
          align: 'center',
          dataIndex: 'transactionNo',
          fixed: 'left',
        },
        {
          title: '状态',
          width: '150px',
          key: 'loadStatus',
          align: 'center',
          dataIndex: 'loadStatus',
          customRender: (text) => {
            let ret = ''
            if (text == 0) {
              ret = '待调度'
            } else if (text == 1) {
              ret = '提货中'
            } else {
              ret = '已入库'
            }
            return ret
          },
        },
        {
          title: '货物信息',
          width: '350px',
          key:'goodsInfo',
          align: "center",
          dataIndex:'goodsInfo'
        },
        {
          title: '到库库房',
          width: '150px',
          key:'warehouse',
          align: "center",
          dataIndex:'warehouse'
        },
        {
          title: '线路',
          width: '220px',
          key: 'lineName',
          align: 'center',
          dataIndex: 'lineName',
        },
        {
          title: '温度',
          width: '120px',
          key: 'temperature',
          align: 'center',
          dataIndex: 'temperature',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '冷藏'
            } else if (text == 2) {
              ret = '冷冻'
            } else {
              ret = '常温'
            }
            return ret
          },
        },
        {
          title: '需要提货',
          width: '150px',
          key: 'loadGoods',
          align: 'center',
          dataIndex: 'loadGoods',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '需要提货'
            } else {
              ret = '自送到库'
            }
            return ret
          },
        },
        {
          title: '司机',
          width: '150px',
          key: 'driver',
          align: 'center',
          dataIndex: 'driver',
        },
        {
          title: '下单时间',
          width: '220px',
          key: 'orderDate',
          align: 'center',
          dataIndex: 'orderDate',
        },
        {
          title: '取消状态',
          width: '150px',
          key: 'revokeType',
          align: 'center',
          dataIndex: 'revokeType',
          customRender: (text) => {
            let ret = ''
            if (text !=null && text>0) {
              ret = '已取消'
            } else {
              ret = '正常'
            }
            return ret
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          align: 'center',
          width: '120px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        param.type = 2
        if (param.loadStatus == 1) {
          param.loadStatusIn = [1]
        } else if (param.loadStatus == 2) {
          param.loadStatusIn = [2]
        } else {
          param.loadStatusIn = [1, 2]
        }
        param.transportType = 2
        param.warehouseId = this.thisWarehouse
        if(this.queryParam.orderDateStart!=null){
          param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
        }
        if(this.queryParam.orderDateEnd!=null){
          param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
        }
        param.paymentCustomerCode = this.queryParam.paymentCustomerCode
        param.findPageOrderBys = "load_status asc"
        let qloadStatus = this.$route.params.loadStatus
        console.info("----------页面默认传参"+qloadStatus);
        if(param.loadStatus==null&&qloadStatus!=null){
          param.loadStatus = qloadStatus
        }
        param.corderStateIn = [2,6]
        return orderFindPage(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      visible_add2: false,
      form: this.$form.createForm(this),
      form2: this.$form.createForm(this),
      queryForm:this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      driverList: [],
      driverInfoList: [],
      newDriver: true, //新司机，只有当选择手动录入司机是司机姓名电话可编辑
      warehouseList: [],
      thisWarehouse: null,
      scrollHeight: 0,
      visible_import:false,
      qyCustomers:[]
    }
  },
  created() {
    let that = this
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 455
    this.warehouseList = []
    findListWarehouse({ status: 1 }).then((res) => {
      for (let i in res.result) {
        that.warehouseList.push({ id: res.result[i].id, name: res.result[i].name })
      }
    })
    findListCustomer({type:1})
      .then(res => {
        this.qyCustomers = []
        for(let i in res.result){
          this.qyCustomers.push(res.result[i])
        }

      }).catch((e)=>{
      this.$message.error("查询企业客户列表失败")
    })
  },
  activated() {
    //跳转过来初始化条件
    let qloadStatus = this.$route.params.loadStatus
    console.info("默认传参：qloadStatus："+qloadStatus)
    if(qloadStatus!=null){
      this.queryParam.loadStatus = qloadStatus+""
    }else{
      this.queryParam = {}
    }
    this.$refs.table.refresh(true)
  },
  filters: {},
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        "token": token,
      }
    },
  },
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    updateStatus(record) {
      let targetstatus = 1
      if (record.status == 1) {
        targetstatus = 0
      }
      updateOrderWarehouse({ id: record.id, status: targetstatus })
        .then((res) => {
          //提交成功
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    changeLoadStatus(e){
      console.info("--当前选中："+e)
      this.queryParam.loadStatus = e
      this.queryForm.setFieldsValue({
        "queryParam.loadStatus":e
      })

    },
    handleOk() {},
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    openDispatch() {
      //判断是否包含不需调度的订单
      let that = this
      console.info('---' + this.selectedRows)
      if (this.selectedRows.length <= 0) {
        this.$message.error('请选择订单')
        return false
      }
      for (let i in this.selectedRows) {
        if (this.selectedRows[i].loadGoods != 1) {
          this.$message.error('选择订单中包含自送到库的订单，不能调度')
          return false
        }
      }
      this.visible_add = true
      this.tabObj = {}
      //初始化司机列表和仓库列表
      this.driverList = []
      this.driverList.push({ id: 0, name: '手动录入' })
      findListDriver({ status: 1 }).then((res) => {
        for (let i in res.result) {
          that.driverList.push({ id: res.result[i].id, name: res.result[i].name + res.result[i].mobile })
        }
        that.driverInfoList = res.result
      })
      this.warehouseList = []
      findListWarehouse({ status: 1 }).then((res) => {
        for (let i in res.result) {
          that.warehouseList.push({ id: res.result[i].id, name: res.result[i].name })
        }
      })
    },
    unDispatch() {
      let that = this
      if (this.selectedRows.length <= 0) {
        this.$message.error('请选择订单')
        return false
      }
      let param = {}
      param.type = 1
      param.status = 0
      param.orderIds = that.selectedRowKeys
      unLoadDispacth(param).then((res) => {
        console.info(res.code)
        if (res.code == 0) {
          that.$message.success('更新成功')
        } else {
          that.$message.error(res.message)
        }
      })
    },
    updateOrderWarehouse() {
      let that = this
      if (this.selectedRows.length <= 0) {
        this.$message.error('请选择订单')
        return false
      }
      this.visible_add2 = true
      this.tabObj = {}
      this.warehouseList = []
      findListWarehouse({ status: 1 }).then((res) => {
        for (let i in res.result) {
          that.warehouseList.push({ id: res.result[i].id, name: res.result[i].name })
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderIds = that.selectedRowKeys
          values.type = 1
          console.log('Received values of form: ', values)
          loadDispacth(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('更新成功')
                that.visible_add = false
                that.$refs.table.refresh()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    handleSubmit2(e) {
      e.preventDefault()
      let that = this
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.orderIds = that.selectedRowKeys
          values.type = 1
          console.log('Received values of form: ', values)
          updateOrderWarehouse(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('更新成功')
                that.visible_add2 = false
                that.$refs.table.refresh()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    dispatchHandleCancel() {
      this.visible_add = false
    },
    dispatchHandleCancel2() {
      this.visible_add2 = false
    },
    handleChange2(value) {
      if (value == 0) {
        this.newDriver = true
      } else {
        this.newDriver = false
        let selectedDriver = {}
        for (let i in this.driverInfoList) {
          if (this.driverInfoList[i].id == value) {
            selectedDriver = this.driverInfoList[i]
            break
          }
        }

        this.form.setFieldsValue({
          name: selectedDriver.name,
          mobile: selectedDriver.mobile,
        })
      }
    },
    handleChange3(value) {
      this.thisWarehouse = value
    },
    gotoWarehouse(record) {
      this.$router.push({ name: 'inStorage', query: { orderId: record.id } })
    },
    orderInfo(record) {
      this.$router.push({ name: 'orderManagerDetail', query: { orderSn: record.orderSn } })
    },
    inWarehouse() {
      let that = this
      let ordersns = []
      for(let i in this.selectedRows){
        ordersns.push(this.selectedRows[i].transactionNo)
      }
      this.$confirm({
        title: '提示',
        content: '确认入库吗 ?',
        onOk: () => {
          // console.info("dddddddddddddd"+this.selectedRowKeys)
          let values = {}
          values.orderIds = this.selectedRowKeys
          values.type = 1
          values.status = 6
          updateInWarehouseStatus(values).then((res) => {
            if(res.code==0){
              that.$message.success('保存成功')
              that.$refs.table.refresh()
            }else{
              that.$message.error(res.message)
            }

          })
        },
        onCancel() {},
      })
    },
    inWarehouseSingle(orderId) {
      let that = this
      this.$confirm({
        title: '提示',
        content: '确认入库吗 ?',
        onOk: () => {
          // console.info("dddddddddddddd"+this.selectedRowKeys)
          let values = {}
          values.orderIds = []
          values.orderIds[0] = orderId
          values.type = 1
          values.status = 6
          updateInWarehouseStatus(values).then((res) => {
            if(res.code==0){
              that.$message.success('保存成功')
              that.$refs.table.refresh()
            }else{
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      })
    },
    exportOrders() {
      let param = {}
      param = this.queryParam
      param.type = 2
      if (param.loadStatus == 1) {
        param.loadStatusIn = [1]
      } else if (param.loadStatus == 2) {
        param.loadStatusIn = [2]
      } else {
        param.loadStatusIn = [1, 2]
      }
      param.transportType = 2
      param.warehouseId = this.thisWarehouse

      if(this.queryParam.orderDateStart!=null){
        param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
      }
      if(this.queryParam.orderDateEnd!=null){
        param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
      }
      param.corderStateIn = [2,6]
      let token = Vue.ls.get(ACCESS_TOKEN)
      axios({
        method: 'post',
        url: '/service_provider/order/export',
        data: param,
        responseType: 'blob',
        headers: {
          token: token,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then((res) => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)

        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = '入库数据.xls' //下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.exportShow = false
      })
    },
    importOrders(){
      this.visible_import = true
    },
    edit(record) {
      // this.$router.push({path:'/orderServe/orderManagerDetail', query: {"orderSn": record.orderSn}})
      this.$router.push({
        path: '/transport_manage/order_detail_dis',
        query: { orderSn: record.transactionNo, fromroute: 'InStorage', orgOrderId: record.id },
      })
    },
    handleCancel(){
      this.visible_import = false
    },
    uploadChange(info){
      let that = this
      if (info.file.status === 'uploading') {
        this.loading = true
        return;
      }
      if (info.file.status === 'done') {
        //导入结果显示
        if(info.file.response.code==0){
          that.visible_add=false
          that.$refs.table.refresh()
        }else {
          that.$message.error(info.file.response.message)
        }
      }
    }
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-label {
  text-align: left;
  width: 100px !important;
}
.dislodge-script{
 /deep/ .ant-form-item-label label::after{
    content:'' !important;
  }
}

</style>
<style lang="less">
.ant-table-thead > tr > th {
  text-align: center;
}
</style>