import { render, staticRenderFns } from "./InStorage.vue?vue&type=template&id=8ed3ae26&scoped=true&"
import script from "./InStorage.vue?vue&type=script&lang=js&"
export * from "./InStorage.vue?vue&type=script&lang=js&"
import style0 from "./InStorage.vue?vue&type=style&index=0&id=8ed3ae26&lang=less&scoped=true&"
import style1 from "./InStorage.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ed3ae26",
  null
  
)

export default component.exports